export const MENU_ITEMS = [
  {
    label: "What is secd3v",
    path: "/#what-is-secd3v",
    offset: "70",
  },
  {
    label: "How to Get Started",
    path: "/how-to-get-started",
    offset: "70",
  },
  {
    label: "Problems We Solve",
    path: "/problems-we-solve",
    offset: "70",
  },
  {
    label: "Pricing",
    path: "/pricing",
    offset: "70",
  },
  {
    label: "Enquiry",
    path: "/enquiry",
    offset: "70",
  },
];

import banner1 from "../../../common/assets/image/secD3v/banner-1.svg";
import banner2 from "../../../common/assets/image/secD3v/banner-1.png";
import banner3 from "../../../common/assets/image/secD3v/banner-1.png";
import intro1 from "../../../common/assets/image/secD3v/intro-1.svg";
import blog1 from "../../../common/assets/image/secD3v/blog-1.jpg";
import blog2 from "../../../common/assets/image/secD3v/blog-2.jpg";
import blog3 from "../../../common/assets/image/secD3v/blog-3.jpg";
import company1 from "../../../common/assets/image/secD3v/brand-1-1.svg";
import company2 from "../../../common/assets/image/secD3v/brand-1-2.svg";
import company3 from "../../../common/assets/image/secD3v/brand-1-3.svg";
import company4 from "../../../common/assets/image/secD3v/brand-1-4.png";
import company5 from "../../../common/assets/image/secD3v/brand-1-5.png";
import company6 from "../../../common/assets/image/secD3v/brand-1-6.png";
import bannerContact from "../../../common/assets/image/secD3v/banner-contact.png";
import bannerAbout from "../../../common/assets/image/secD3v/banner-about.png";
import bannerPayments from "../../../common/assets/image/secD3v/banner-payments.png";
import bannerImage1 from "../../../common/assets/image/x-rd-white.png";
import bannerGetStarted from "../../../common/assets/image/secD3v/banner-get-started.svg";
import bannerProblemWeSolve from "../../../common/assets/image/secD3v/banner-problems-we-solve.svg";
import bannerPricing from "../../../common/assets/image/secD3v/banner-pricing.svg";
import imageGettingStarted1 from "../../../common/assets/image/secD3v/section-getting-started-1.png";
import imageGettingStarted2 from "../../../common/assets/image/secD3v/section-getting-started-2.png";

import bubbleImage1 from "../../../common/assets/image/secD3v/call-bubble-1.svg";
import bubbleImage2 from "../../../common/assets/image/secD3v/call-bubble-2.svg";
import bubbleImage3 from "../../../common/assets/image/secD3v/call-bubble-3.svg";
import bubbleImage4 from "../../../common/assets/image/secD3v/call-bubble-4.svg";
import bubbleImage5 from "../../../common/assets/image/secD3v/call-bubble-5.svg";
import bubbleImage6 from "../../../common/assets/image/secD3v/call-bubble-6.svg";
import bubbleImage7 from "../../../common/assets/image/secD3v/call-bubble-7.svg";
import customerSupportImageShape from "../../../common/assets/image/secD3v/customer-support-shape.svg";
import customerSupportImage from "../../../common/assets/image/secD3v/customer-support.png";
import benefits1 from "../../../common/assets/image/secD3v/benefits-1.svg";
import benefits2 from "../../../common/assets/image/secD3v/benefits-2.svg";
import benefits3 from "../../../common/assets/image/secD3v/benefits-3.svg";
import benefits4 from "../../../common/assets/image/secD3v/benefits-4.svg";
import benefits5 from "../../../common/assets/image/secD3v/benefits-5.svg";
import benefits6 from "../../../common/assets/image/secD3v/benefits-6.svg";
import benefits7 from "../../../common/assets/image/secD3v/benefits-7.svg";
import benefits8 from "../../../common/assets/image/secD3v/benefits-8.svg";
import footerPdf1 from "../../../../src/common/assets/docs/secd3v-website-terms-of-use.pdf";
import footerPdf2 from "../../../../src/common/assets/docs/secd3v-website-and-service-privacy-policy.pdf";
import footerLinkedIn from "../../../common/assets/image/secD3v/footer-linkedin.svg";
import footerTwitter from "../../../common/assets/image/secD3v/footer-twitter.svg";
import footerLogo from "../../../common/assets/image/secD3v/header-logo-stick.svg";
import secure2 from "../../../common/assets/image/secD3v/secure-1.png";
import secure1 from "../../../common/assets/image/secD3v/secure-2.png";
import secureImage from "../../../common/assets/image/secD3v/secure-dashboard.png";
import service1 from "../../../common/assets/image/secD3v/service-1.png";
import service2 from "../../../common/assets/image/secD3v/service-2.png";
import industry1 from "../../../common/assets/image/secD3v/industry-1.svg";
import industry2 from "../../../common/assets/image/secD3v/industry-2.svg";
import industry3 from "../../../common/assets/image/secD3v/industry-3.svg";
import industry4 from "../../../common/assets/image/secD3v/industry-4.svg";
import product1 from "../../../common/assets/image/secD3v/product-what-is-it.svg";
import product2 from "../../../common/assets/image/secD3v/product-how-it-works.svg";
import testimonial1 from "../../../common/assets/image/secD3v/testimonials-1-1.png";
import testimonial2 from "../../../common/assets/image/secD3v/testimonials-1-2.png";
import testimonial3 from "../../../common/assets/image/secD3v/testimonials-1-3.png";
import testimonialBlockImage from "../../../common/assets/image/secD3v/testimonials-block-image.png";
export const BANNER_DATA = {
  title: "Your strategic investment in DevSecOps efficiency.",
  text: "secd3v is an Australian, accredited DevSecOps platform powered by GitLab: Driving your software and AI with measurable ROI.",
  tagline: "*No Credit card required",
  button: {
    label: "Find Out More",
    link: "/enquiry",
  },
  image: [
    {
      src: banner1,
    },
  ],
};

export const BANNER_GETSTARTED_DATA = {
  title: "Your journey with secd3v starts today.",
  text: "secd3v is an Australian, accredited DevSecOps platform powered by GitLab: Driving your software and AI with measurable ROI.",
  tagline: "*No Credit card required",
  button: {
    label: "Make your enquiry",
    link: "/enquiry",
  },
  image: [
    {
      src: bannerGetStarted,
    },
  ],
};

export const BANNER_PRICING_DATA = {
  title: "Discover transparent pricing for seamless protection",
  text: "The base price for secd3v is A$99/user/month with a minimum deployment of 25 licences.  Additional services are available based on the number of users and options chosen, summarised in the tables below.",
  tagline: "*No Credit card required",
  button: {
    label: "Find Out More",
    link: "/enquiry",
  },
  image: [
    {
      src: bannerPricing,
    },
  ],
};

export const BANNER_PROBLEM_SOLVE_DATA = {
  title: "Problems we solve to revolutionise your infrastructure",
  text: "secd3v addresses critical business challenges by streamlining automation, enhancing security, and improving team collaboration. We help organisations reduce operational costs, increase efficiency, and scale seamlessly with our innovative GitLab powered platform.",
  tagline: "*No Credit card required",
  button: {
    label: "Find Out More",
    link: "/enquiry",
  },
  image: [
    {
      src: bannerProblemWeSolve,
    },
  ],
};

export const BANNER_ENQUIRY_DATA = {
  title: "Make an Enquiry",
  text: "secd3v is powered by x-RD - get in touch with us learn more about how secd3v can revolutionise your team's software development - with security and speed.",
  tagline: "*No Credit card required",
  button: {
    label: "Find Out More",
    link: "/enquiry",
  },
  image: [
    {
      src: bannerGetStarted,
    },
  ],
};

export const BENEFITS_DATA = {
  blockTitle: {
    MainTitle: "Benefits",
    text: "",
  },
  post: [
    {
      icon: benefits1,
      title: "Streamlined Experience",
      description: "User-friendly DevSecOps platform streamlines operations.",
    },
    {
      icon: benefits2,
      title: "Separate Tenancies",
      description:
        "Dedicated infrastructure providing isolation of tenancy data.",
    },
    {
      icon: benefits3,
      title: "High Availability",
      description: "Ensures uninterrupted operation and uptime.",
    },
    {
      icon: benefits4,
      title: "Rapid Deployment",
      description: "Speeds up processes with quick setup and provisioning.",
    },
    {
      icon: benefits5,
      title: "Robust Security",
      description: "IRAP assessed platform ensures data protection.",
    },
    {
      icon: benefits6,
      title: "Proactive Monitoring",
      description: "Offers threat monitoring and swift incident response.",
    },
    {
      icon: benefits7,
      title: "Cost Efficiency",
      description: "Provides high-value services at a cost-effective rate.",
    },
    {
      icon: benefits8,
      title: "Australian",
      description:
        "Operated from Australia, catering to Australian cyber security needs.",
    },
  ],
};

export const SECTION_FEATURES_DATA = {
  blockTitle: {
    MainTitle: "Features",
    text: "",
  },
  post: [
    {
      title: "Scalability",
      description:
        "Enhances growth with efficient scalability, saving costs over traditional methods.",
    },
    {
      title: "Security",
      description:
        "Provides robust data security through comprehensive ISM hardening.",
    },
    {
      title: "Rapid Deployment",
      description:
        "Deploys environments in under 30 minutes, reducing time and associated costs.",
    },
    {
      title: "Flexibility & Agility",
      description:
        "Provides on-demand provisioning to meet diverse needs without financial excess.",
    },
    {
      title: "Operational Efficiency",
      description:
        "Enhances workflows, leading to financial savings and improved business productivity.",
    },
    {
      title: "Compliance & Integration",
      description:
        "Deploys environments in under 30 minutes, reducing time and associated costs.",
    },
  ],
};

export const PROBLEMS_WE_SOLVE_DATA = {
  blockTitle: {
    MainTitle: "The Problems we Solve",
    text: "",
  },
  post: [
    {
      icon: benefits1,
      title: "Enterprise Ready",
      description: "User-friendly DevSecOps platform streamlines operations.",
    },
    {
      icon: benefits3,
      title: "Security",
      description:
        "IRAP assessed platform ensures data protection and compliance.",
    },
    {
      icon: benefits2,
      title: "Collaboration",
      description:
        "Facilitates separate provision for diverse organisational needs.",
    },
    {
      icon: benefits4,
      title: "Support",
      description: "Ensures uninterrupted operation and uptime.",
    },
    {
      icon: benefits6,
      title: "Mitigating Risk",
      description: "Offers threat monitoring and swift incident response.",
    },
    {
      icon: benefits4,
      title: "cATO",
      description: "Speeds up processes with quick setup and provisioning.",
    },
  ],
};

export const ENQUIRY_DATA = {
  blockTitle: {
    MainTitle: "Equiry",
    text: "",
  },
  post: [
    {
      icon: benefits1,
      title: "Enterprise Ready",
      description: "User-friendly DevSecOps platform streamlines operations.",
    },
    {
      icon: benefits3,
      title: "Security",
      description:
        "IRAP assessed platform ensures data protection and compliance.",
    },
    {
      icon: benefits2,
      title: "Collaboration",
      description:
        "Facilitates separate provision for diverse organisational needs.",
    },
    {
      icon: benefits4,
      title: "Support",
      description: "Ensures uninterrupted operation and uptime.",
    },
    {
      icon: benefits6,
      title: "Mitigating Risk",
      description: "Offers threat monitoring and swift incident response.",
    },
    {
      icon: benefits4,
      title: "cATO",
      description: "Speeds up processes with quick setup and provisioning.",
    },
  ],
};

export const REGISTER_FORM_DATA = {
  blockTitle: {
    MainTitle: "Reach out and learn more about secd3v",
    text: "Discover how secd3v can be a strategic financial investment for your organisation. Embrace a solution that offers security, compliance, and significant ROI advantages.",
  },
};

export const OURPRODUCT_DATA = [
  {
    image: product1,
    title: "What it Is",
    text: "secd3v DevSecOps is a sovereign, IRAP assessed, and multi-tenanted GitLab-based software factory. This comprehensive as-a-service solution seamlessly integrates with your corporate and enterprise networks.",
  },
  {
    image: product2,
    title: "How it Works",
    text: "Providing DevSecOps-as-a-Service, SecD3v leverages the GitLab platform, and comes equipped with secure software patterns, templates, and third-party security tools, with the promise of automated GRC reporting in the near future.",
  },
];

export const ACROSS_GOVERNMET_DATA = [
  {
    icon: product1,
    title: "Enforcement and Justice",
    text: "secd3v enhances secure digital operations, aiding effective crime prevention and streamlined justice administration.",
  },
  {
    icon: product2,
    title: "Health and Social Services",
    text: "secd3v safeguards critical health data, fortifying privacy and enabling seamless delivery of social care services.",
  },
  {
    icon: product1,
    title: "Defence and National Security",
    text: "By bolstering security operations, secd3v ensures the protection of national interests and sovereignty.",
  },
  {
    icon: product2,
    title: "Education and Research",
    text: "secd3v supports the pursuit of academic excellence and research advancement, ensuring robust data protection.",
  },
  {
    icon: product1,
    title: "Transportation and Infrastructure",
    text: "Driving secure digital innovation with secd3v enhances efficiency and safety in the transport and infrastructure sector.",
  },
  {
    icon: product2,
    title: "Environmental Agencies",
    text: "secd3v facilitates secure data management, promoting robust and sustainable environmental governance.",
  },
];

export const ACROSS_FINANCIAL_DATA = [
  {
    icon: product1,
    title: "Retail Banking:",
    text: "Seamlessly conduct operations and transactions while ensuring customer data is always safeguarded and regulatory compliance is met.",
  },
  {
    icon: product2,
    title: "Banking",
    text: "Strengthen digital transformation with secd3v's secure platform, instilling customer trust and meeting stringent regulatory requirements.",
  },
  {
    icon: product1,
    title: "Insurance",
    text: "Mitigate risks and ensure compliance with secd3v, protecting sensitive client data and enabling innovative digital services.",
  },
  {
    icon: product2,
    title: "Asset Management",
    text: "Implement secure, efficient digitisation with secd3v, safeguarding client investments and fostering operational excellence.",
  },
  {
    icon: product1,
    title: "Financial Market Infrastructure",
    text: "Enhance the financial system's resilience with secd3v's robust security, securing transactions and maintaining uninterrupted operations.",
  },
  {
    icon: product2,
    title: "Capital Markets",
    text: "Facilitate secure trading and efficient data management with secd3v, boosting market confidence and ensuring regulatory compliance.",
  },
  {
    icon: product2,
    title: "Consumer Finance",
    text: "Safeguard customer data with secd3v, fostering trust while seamlessly navigating the digital landscape of consumer finance.",
  },
  {
    icon: product2,
    title: "Financial Regulators",
    text: "Utilise secd3v's secure platform to enforce industry standards, protect sensitive data, and effectively regulate the financial ecosystem.",
  },
  {
    icon: product2,
    title: "Superannuation Funds",
    text: "Secure member retirement savings with secd3v's leading-edge digital safety, enhancing operational efficiency and member confidence.",
  },
];

export const KEYFEATURES_DATA = [
  {
    icon: service1,
    title: "Scalability",
    text: "secd3v accommodates growth in user base efficiently with its high availability deployment, based on the cloud-native GitLab reference pattern.",
  },
  {
    icon: service2,
    title: "Security",
    text: "Robust data security is assured with secd3v through its comprehensive ISM security hardening, monitoring, and incident response services.",
  },
  {
    icon: service1,
    title: "Rapid Deployment",
    text: "secd3v enables rapid DevSecOps environment setup, providing fully automated instances and new tenancies within 30 minutes.",
  },
  {
    icon: service2,
    title: "Flexibility and Agility",
    text: "With on-demand provisioning, secd3v caters to specific needs like higher classified work, temporary tasks, and custom collaborations.",
  },
  {
    icon: service2,
    title: "Isolated Infrastructure",
    text: "All SecD3v infrastructure for a tenancy is exclusively for the use of that tenancy. No infrastructure is shared between tenants.",
  },
  {
    icon: service2,
    title: "Streamlined Deployment",
    text: "secd3v streamlines the DevSecOps deployment process by providing a managed GitLab instance, reducing operational complexity.",
  },
  {
    icon: service2,
    title: "Compliance",
    text: "secd3v addresses compliance challenges by offering certifications including ISO27001, SOC2/3 pending, and IRAP assessment.",
  },
  {
    icon: service2,
    title: "Integration",
    text: "secd3v simplifies the integration process into corporate, government, and enterprise networks while meeting strict security requirements.",
  },
  {
    icon: service2,
    title: "ML Support",
    text: "secd3v efficiently facilitates the management and deployment of software and machine learning workloads.",
  },
  // {
  //   icon: service2,
  //   title: "Documentation",
  //   text: "secd3v enhances transparency by providing security teams with a concise security documentation pack.",
  // },
];

export const INDUSTRY_USE_CASE_DATA = [
  {
    icon: industry1,
    title: "Government",
    text: "Advanced data protection for sensitive defence operations.",
    button: "Learn More",
    path: "/government",
  },
  {
    icon: industry2,
    title: "Healthcare",
    text: "Safeguarded healthcare data and streamlined HIPAA compliance.",
    button: "Coming Soon",
    path: "#",
  },
  {
    icon: industry3,
    title: "Financial Services",
    text: "Enhanced cybersecurity and compliance in finance transactions.",
    button: "Learn More",
    path: "/financial-services",
  },
  {
    icon: industry4,
    title: "Defence Contractors",
    text: "Fortified data security for private sector defence contracts.",
    button: "Coming Soon",
    path: "#",
  },
];

export const COMPANY_DATA = {
  title: "Key Partners",
  images: [
    {
      src: company1,
      url: "https://aws.amazon.com/",
    },
    {
      src: company2,
      url: "https://about.gitlab.com/",
    },
    {
      src: company3,
      url: "https://www.salesforce.com/",
    },
  ],
};

export const EXPERTISE_DATA = {
  sectionImage: secureImage,
  blockTitle: {
    title: "Expertise",
  },
  button: {
    link: "#",
    label: "Learn more",
  },
  posts: [
    {
      icon: secure1,
      text: "We focus on ‘doing’ at speed, and believe that solving difficult and uncertain problems with technology and AI can only be achieved through multi-disciplined expert experimentation, engineering and execution. The human aspect and other societal factors are equally important in any solution, and we aim to achieve this holistic approach through experimentation.",
    },
    {
      icon: secure2,
      text: "and partnerships with leaders, innovators and game-changers in these spaces. Using technology and our experience to make a real difference is important to x-RD and our staff, and we are proud of our work in developing digital solutions for philanthropic causes and societal needs.",
    },
  ],
};

export const SECURE_DASHBOARD_DATA = {
  sectionImage: secureImage,
  blockTitle: {
    title: "Most Promising & Secure Dashboard with tracking user referral code",
    text: "Pick one of our stock themes, or create your custom theme with the most advanced theme editor on any online survey building tool.",
  },
  posts: [
    {
      icon: secure1,
      title: "Fast & Instant Transfer",
      text: "We’re driven beyond just finishing the projects. We want to find solutions.",
    },
    {
      icon: secure2,
      title: "File Management System",
      text: "We’re driven beyond just finishing the projects. We want to find solutions.",
    },
  ],
};

export const INTRO_DATA = {
  blockTitle: {
    title: "Defining Innovative & Responsible Technology Experiences",
    text: "Specialising in artificial intelligence, software development & automation, cyber security and applied research, we thrive when challenged with complex and difficult problems. We are motivated to make positive change through our expertise, engineering, products and services.",
    button: {
      link: "#",
      label: "About Us",
    },
  },
  image: intro1,
};

export const COUNTER_DATA = {
  blockTitle: {
    title: "Defining Innovative & Responsible Technology Experiences",
    text: "Specialising in artificial intelligence, software development & automation, cyber security and applied research, we thrive when challenged with complex and difficult problems. We are motivated to make positive change through our expertise, engineering, products and services.",
    button: {
      link: "#",
      label: "About Us",
    },
  },
  posts: [
    {
      count: "80",
      title: "Up to",
      text: "Customer Response",
      symbol: "%",
    },
    {
      count: "99",
      title: "Consistent",
      text: "Performance Score",
      symbol: "%",
    },
    {
      count: "3.5",
      title: "Down to",
      text: "Response Time",
      symbol: "S",
    },
    {
      count: "5x",
      title: "Up to",
      text: "Faster then others",
      symbol: "",
    },
  ],
};

export const TESTIMONIALS_DATA = {
  blockImage: testimonialBlockImage,
  title: "What people say about our product",
  posts: [
    {
      image: testimonial1,
      text: "OMG! I cannot believe that I have got a brand new landing page after getting this template we are able to use our most used e-commerce template with modern and trending design.",
      name: "Mariana Dickey",
      designation: "UI Designer",
    },
    {
      image: testimonial2,
      text: "OMG! I cannot believe that I have got a brand new landing page after getting this template we are able to use our most used e-commerce template with modern and trending design.",
      name: "Jonathan Taylor",
      designation: "CEO at Creativex",
    },
    {
      image: testimonial3,
      text: "OMG! I cannot believe that I have got a brand new landing page after getting this template we are able to use our most used e-commerce template with modern and trending design.",
      name: "Krish Kendall",
      designation: "Creative Director",
    },
  ],
};

export const PRODUCTS_DATA = [
  {
    product: 0,
    title: "secd3v",
    price: "Free Trial",
    tagline: "with restrictions",
    planLabel: "Features:",
    options: [
      {
        text: "Manage conversations directly from your websites optimization.",
      },
      {
        text: "Unlimited links",
      },
      {
        text: "Optimzed hashtags",
      },
      {
        text: "Own analytics platform",
      },
    ],
    button: {
      label: "Learn More",
      link: "/secd3v",
    },
  },
  {
    product: 0,
    title: "Hab1tat",
    price: "Free Trial",
    tagline: "with restrictions",
    planLabel: "Features:",
    options: [
      {
        text: "Manage conversations directly from your websites optimization.",
      },
      {
        text: "Unlimited links",
      },

      {
        text: "Optimzed hashtags",
      },
      {
        text: "Own analytics platform",
      },
    ],
    button: {
      label: "Learn More",
      link: "#",
    },
  },
  {
    product: 0,
    title: "Services",
    price: "Free Trial",
    tagline: "with restrictions",
    planLabel: "Features:",
    options: [
      {
        text: "Manage conversations directly from your websites optimization.",
      },
      {
        text: "Unlimited links",
      },

      {
        text: "Optimzed hashtags",
      },
      {
        text: "Own analytics platform",
      },
    ],
    button: {
      label: "Learn More",
      link: "#",
    },
  },
];

export const MONTHLY_PRICING_DATA = [
  {
    recommended: false,
    title: "1-10 Users",
    price: "$99.00",
    tagline: "Per User/Month",
    planLabel: "Includes:",
    options: [
      {
        text: "Secure DevSecOps environment",
      },
      {
        text: "Official, Protected Classifications",
      },
      {
        text: "Cloud Environment included in costs",
      },
      {
        text: "Bronze level support time",
      },
      {
        text: "Capped data use per tier",
      },
      {
        text: "Custom Choices Available",
      },
    ],
    button: {
      label: "Register",
      link: "#",
    },
  },
  {
    recommended: false,
    title: "11-50 Users",
    price: "$99.00",
    tagline: "Per User/Month",
    planLabel: "Includes:",
    options: [
      {
        text: "Secure DevSecOps environment",
      },
      {
        text: "Official, Protected Classifications",
      },
      {
        text: "Cloud Environment included in costs",
      },
      {
        text: "Silver level support time",
      },
      {
        text: "Capped data use per tier",
      },
      {
        text: "Custom Choices Available",
      },
    ],
    button: {
      label: "Register",
      link: "#",
    },
  },
  {
    recommended: true,
    title: "50+ Users",
    price: "$99.00",
    tagline: "Per User/Month",
    planLabel: "Includes:",
    options: [
      {
        text: "Secure DevSecOps environment",
      },
      {
        text: "Official, Protected Classifications",
      },
      {
        text: "Cloud Environment included in costs",
      },
      {
        text: "Gold level support time",
      },
      {
        text: "Capped data use per tier",
      },
      {
        text: "Custom Choices Available",
      },
    ],
    button: {
      label: "Contact",
      link: "#",
    },
  },
];

export const YEARLY_PRICING_DATA = [
  {
    recommended: false,
    title: "Free",
    price: "Free",
    tagline: "with restrictions",
    planLabel: "Plan includes:",
    options: [
      {
        text: "Manage conversations directly from your websites optimization.",
      },
      {
        text: "Unlimited links",
      },
      {
        text: "Chat promt supported",
      },
      {
        text: "Optimzed hashtags",
      },
      {
        text: "Own analytics platform",
      },
    ],
    button: {
      label: "Purchase",
      link: "#",
    },
  },
  {
    recommended: false,
    title: "Individual",
    price: "$99.93",
    tagline: "Yearly",
    planLabel: "Plan includes:",
    options: [
      {
        text: "Manage conversations directly from your websites optimization.",
      },
      {
        text: "Unlimited links",
      },
      {
        text: "Chat promt supported",
      },
      {
        text: "Optimzed hashtags",
      },
      {
        text: "Own analytics platform",
      },
    ],
    button: {
      label: "Purchase",
      link: "#",
    },
  },
  {
    recommended: true,
    title: "Team",
    price: "$199.93",
    tagline: "Yearly",
    planLabel: "Plan includes:",
    options: [
      {
        text: "Manage conversations directly from your websites optimization.",
      },
      {
        text: "Unlimited links",
      },
      {
        text: "Chat promt supported",
      },
      {
        text: "Optimzed hashtags",
      },
      {
        text: "Own analytics platform",
      },
    ],
    button: {
      label: "Purchase",
      link: "#",
    },
  },
];

export const CUSTOMER_SUPPORT_DATA = {
  image: customerSupportImage,
  shapeImage: customerSupportImageShape,
  title: "Meet our Superheros who works dedicatedly with customer support",
  options: [
    {
      label: "Gift-giving social network – emotional vs transactional",
    },
    {
      label:
        "Use gifts and consumer groups relationships to drive traffic to your business",
    },
    {
      label:
        "Significantly underserved gift-card and e-gifting industry in Mexico and Latin America",
    },
    {
      label: "Social currency",
    },
    {
      label: "20 second gifting process",
    },
  ],
  button: {
    link: "#",
    label: "Learn More",
  },
};

export const BLOG_DATA = {
  blockText: {
    title: "Differentiators",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  posts: [
    {
      image: blog1,
      title: "Small title",
      link: {
        label:
          "Body placeholder for text paragraph, a paragraph is a self-contained unit of text description.",
        path: "#",
      },
    },
    {
      image: blog2,
      title: "Small title",
      link: {
        label:
          "Body placeholder for text paragraph, a paragraph is a self-contained unit of text description.",
        path: "#",
      },
    },
    {
      image: blog3,
      title: "Small title",
      link: {
        label:
          "Body placeholder for text paragraph, a paragraph is a self-contained unit of text description.",
        path: "#",
      },
    },
  ],
};

export const CALL_TO_ACTION_DATA = {
  title: "Thank you for your interest!",
  text: "A member of secd3v will be in contact shorlty.",
  button: {
    link: "/",
    label: "Return to secd3v",
  },
  bubbleIcon: [
    {
      icon: bubbleImage1,
    },
    {
      icon: bubbleImage2,
    },
    {
      icon: bubbleImage3,
    },
    {
      icon: bubbleImage4,
    },
    {
      icon: bubbleImage5,
    },
    {
      icon: bubbleImage6,
    },
    {
      icon: bubbleImage7,
    },
  ],
};

export const SECTION_CONTACT = {
  sections: {
    contact_us: "Contact Us",
    contact_us_text:
      "Body placeholder for text paragraph, a paragraph is a self-contained unit of text description.",
    phone: "Phone",
    phone_content_act: "02 6109 7672",
    phone_content_vic: "03 8352 1976",
    address: "Address",
    address_content_act: "Suite 2.02, Level 2/15 London Cct, Canberra ACT 2601",
    address_content_vic: "Suite 1, Level 14 350 Collins St, Melbourne 3000",
    button: {
      link: "/about",
      label: "Email us",
    },
    image: "",
  },
};

export const BANNER_CONTACT_DATA = {
  title: "Contact",
  text: "",
  tagline: "*No Credit card required",
  button: {
    label: "Contact Details",
    link: "#contact",
  },

  image: [
    {
      src: bannerContact,
    },
  ],
};

export const BANNER_STATUS_DATA = {
  title: "Status",
  text: "",
  tagline: "*No Credit card required",
  button: {
    label: "Systems Status Details",
    link: "#contact",
  },

  image: [
    {
      src: bannerContact,
    },
  ],
};

export const BANNER_ABOUT_DATA = {
  title: "About",
  text: "",
  tagline: "*No Credit card required",
  button: {
    label: "About us",
    link: "#aboutUs",
  },

  image: [
    {
      src: bannerAbout,
    },
  ],
};

export const BANNER_FINACIAL_DATA = {
  title: "Empowering the Financial Services Industry with secd3v",
  text: "As technology and customer expectations steer the financial services sector towards profound transformation, mastering digitisation and navigating regulatory landscapes have become more crucial than ever.",
  tagline: "*No Credit card required",
  button: {
    label: "Read more",
    link: "#aboutUs",
  },

  image: [
    {
      src: bannerAbout,
    },
  ],
};

export const BANNER_GOVERNMENT_DATA = {
  title: "Empowering Secure DevSecOps in the Public Sector",
  text: "In the whirlwind of digital transformation, the Australian public sector encounters unique security challenges within software development lifecycles, inviting innovative approaches amidst evolving cyber threats.",
  tagline: "*No Credit card required",
  button: {
    label: "Read more",
    link: "#aboutUs",
  },

  image: [
    {
      src: bannerAbout,
    },
  ],
};

export const BANNER_PAYMENTS_DATA = {
  title: "Payments",
  text: "",
  tagline: "*No Credit card required",
  button: {
    label: "Pay now",
    link: "#payments",
  },

  image: [
    {
      src: bannerPayments,
    },
  ],
};

export const SECTION_ABOUT_DATA = {
  sections: {
    about_us: "About Us",
    about_linetag:
      "We help deliver on-demand secure applications easier and faster to meet your sensitive and government business needs",
    about_text:
      "Building, hardening and managing secure applications for sensitive or government workloads is timely, costly and resource intensive. We understand this and have developed the secd3v platform to enable on demand delivery of secure applications for our customers.",
    about_story_title: "Our Story",
    about_story1:
      "We were formed in 2022 from x-RD that have deep roots in software development, cloud engineering and automation, cyber security and applied research.",
    about_story2:
      "In March 2023 we received an IRAP assessment to PROTECTED for our platform and DevSecOps service including GitLab. This acknowledges our ongoing commitment to secure technologies.",
    about_cases_title: "Our use cases",
    image: bannerImage1,
    imageAbout: bannerContact,
  },
};

export const SECTION_PROBLEMS_WE_SOLVE_DATA = {
  sections: {
    title: "The Problems we Solve",
    image: bannerImage1,
  },
};

export const SECTION_GETTING_STARTED_DATA = {
  sections: {
    title1: "How do I get started?",
    title_linetag1:
      "Immediate productivity through simple and fast access to a highly secure DevSecOps environment.",
    title2: "Building Your Secure Application ",
    title_linetag2:
      "The combination of GitLab in secd3v frees you up to focus on what you do best, build great software, with the comfort of operating in a government-grade secure environment.",
    imageGettingStarted1: imageGettingStarted1,
    imageGettingStarted2: imageGettingStarted2,
  },
};

export const SECTION_FINACIAL_DATA = {
  sections: {
    about_us: "Overview",
    about_linetag:
      "Financial services form a central point where ambition meets regulation, where growth is tempered by risk, and where the drive for innovation is ever-increasing. As the industry reshapes in response to technological advancements, digitisation, shifting customer expectations, and regulatory modifications, uncertainty becomes an inherent aspect. Navigating this maze calls for a new approach, a more robust solution.",
    about_text:
      "Enter secD3v, a sovereign, IRAP assessed, and isolated GitLab-based software factory. This comprehensive as-a-service solution integrates seamlessly with your corporate and enterprise networks, providing a robust DevSecOps platform with secure software patterns, templates, and security tools. The future of financial services can confidently thrive with SecD3v, transforming uncertainty into a roadmap for innovation and success.",
    about_story_title: "Our Story",
    about_story1:
      "We were formed in 2022 from x-RD that have deep roots in software development, cloud engineering and automation, cyber security and applied research.",
    about_story2:
      "In March 2023 we received an IRAP assessment to PROTECTED for our platform and DevSecOps service including GitLab. This acknowledges our ongoing commitment to secure technologies.",
    about_cases_title: "Our use cases",
    image: bannerImage1,
    imageAbout: bannerContact,
  },
};

export const SECTION_GOVERNMENT_DATA = {
  sections: {
    about_us: "Overview",
    about_linetag:
      "As government agencies embrace transformation, they grapple with data privacy, regulatory compliance, and escalating cybersecurity threats. Navigating this landscape requires a skillful blend of secure service delivery and agility.",
    about_text:
      "Enter secd3v, a sovereign SaaS platform, designed as a resilient ally for government agencies. Hosted in Australia, it integrates into official networks to provide secure applications for sensitive workloads. With an IRAP assessment up to PROTECTED, it ensures stringent data security, negating the need to manage secure applications and infrastructure. secd3v's on-demand provisioning of secure tenancies boosts operational efficiency, enabling agencies to embrace digital transformation while upholding public trust. Rely on our expertise in secure government environments to bolster your security and innovation journey.",
    about_story_title: "Our Story",
    about_story1:
      "We were formed in 2022 from x-RD that have deep roots in software development, cloud engineering and automation, cyber security and applied research.",
    about_story2:
      "In March 2023 we received an IRAP assessment to PROTECTED for our platform and DevSecOps service including GitLab. This acknowledges our ongoing commitment to secure technologies.",
    about_cases_title: "Our use cases",
    image: bannerImage1,
    imageAbout: bannerContact,
  },
};

export const SECTION_PAYMENT_DATA = {
  sections: {
    about_us: "Payment with Credit Card",
    about_linetag:
      "Pay easily online or on mobile — secured & hosted by CommBank Simplify.",
    image: bannerImage1,
    imageAbout: bannerContact,
  },
};

export const FOOTER_WIDGET = [
  {
    title: "Contact",
    menuItems: [
      {
        url: "#",
        text: "Melbourne ~ Naarm",
      },
      {
        url: "#",
        text: "Canberra ~ Ngambri",
      },
      {
        url: "#",
        text: "info@x-rd.com.au",
      },
      {
        url: "#",
        text: "(02) 6109 7672",
      },
    ],
  },
  {
    title: "Solutions & Services",
    menuItems: [
      {
        url: "#",
        text: "secd3v",
      },
      {
        url: "#",
        text: "Hab1tat",
      },
      {
        url: "#",
        text: "Industries",
      },
      {
        url: "#",
        text: "Case Studies",
      },
    ],
  },
  {
    title: "Company",
    menuItems: [
      {
        url: "#",
        text: "About Us",
      },
      {
        url: "#",
        text: "Partnerships",
      },
      {
        url: "#",
        text: "Philantropic Initiatives",
      },
      {
        url: "#",
        text: "Blog",
      },
      {
        url: "#",
        text: "Careers",
      },
      {
        url: "#",
        text: "Security & Compliance",
      },
    ],
  },
];

export const FOOTER_DATA = {
  logo: footerLogo,
  menu: [
    {
      link: "https://support.secd3v.com.au/",
      label: "Support",
    },
    {
      link: footerPdf2,
      label: "Privacy",
    },
    {
      link: footerPdf1,
      label: "Terms of Use",
    },
    // {
    //   link: "/contact",
    //   label: "Contact",
    // },
    // {
    //   link: "/about",
    //   label: "About",
    // },
  ],
  social: [
    {
      icon: footerTwitter,
      link: "https://twitter.com/xRD_AU",
    },
    {
      icon: footerLinkedIn,
      link: "https://www.linkedin.com/company/65650519",
    },
  ],
};
